import React, { useEffect, useState } from 'react'
import _ from 'lodash';

interface Team{
  team: any,
  users: any[]
}
interface Props{
    participants:any[],
    teams: Team[],
}
const EventParticipants = ({participants, teams}:Props) => {
  const [peoples, setPeoples]= useState<any[]>([]);
  const [userStatus, setUserStatus]=useState<any[]>([]);

  useEffect(()=>{
    let status:any={};
    let latestPeoples= [
        ...participants.map((p:any)=>{
           status[p.user._id]= p?.status;
           return p?.user;
        }),
    ];
    for(let team of teams){
      latestPeoples=latestPeoples.concat(team?.users.map(
        (p:any)=>{
          status[p?.user?._id]= p?.status;
          return p?.user;
        }
      ));
    }
    latestPeoples=_.uniqBy(latestPeoples,"_id");
    setPeoples(latestPeoples);
    setUserStatus(status);
  },[participants, teams]);


  return (
    <div className='my-4'>
        <h5>Members Acceptance</h5>
        {
            peoples?.map((p,i)=>(
                <div key={i}>
                   {i+1}. <span className='text-capitalize'>{p?.firstName} {p?.lastName}</span>
                   <span className='mx-4 text-capitalize fw-bold'>- {userStatus[p?._id]}</span>
                </div>
            ))  || 'No participants'
        }
    </div>
  )
}

export default EventParticipants