import CalendarIcon from "../../../../app/icons/CalendarIcon";
import ContactIcon from "../../../../app/icons/ContactIcon";
import EventIcon from "../../../../app/icons/EventIcon";
import ForumIcon from "../../../../app/icons/ForumIcon";
import NewsLetterIcon from "../../../../app/icons/NewsLetterIcon";
import PartnersIcon from "../../../../app/icons/PartnersIcon";
import RoomIcon from "../../../../app/icons/RoomIcon";
import SubscriptionIcon from "../../../../app/icons/SubscriptionIcon";
import TaskIcon from "../../../../app/icons/TaskIcon";
import WebpageIcon from "../../../../app/icons/WebpageIcon";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to="/users" icon={<PartnersIcon/>} title="Users" />

      <AsideMenuItem to="/events" icon={<EventIcon/>} title="Events" />

      <AsideMenuItem to="/rooms" icon={<RoomIcon/>} title="Rooms" />

      {/* <AsideMenuItem to="/bookings" icon={<CalendarIcon/>} title="Room Bookings" />
       */}
      <AsideMenuItem to="/tasks" icon={<TaskIcon/>} title="Tasks" />

      <AsideMenuItem to="/forums" icon={<ForumIcon/>} title="Forums" />


      <AsideMenuItem to="/subscriptions" icon={<SubscriptionIcon/>} title="Subscritions" />

      <AsideMenuItem to="/webpages" icon={<WebpageIcon/>} title="Web Pages" />

      <AsideMenuItem to="/news-letters" icon={<NewsLetterIcon/>} title="Newsletter" />

      <AsideMenuItem to="/contacts" icon={<ContactIcon/>} title="Contacts" />
      
      {window.location.pathname === '/user/change-password' && <AsideMenuItem to="/user" icon={<PartnersIcon/>} title="Change Password" />}
    </>
  );
}
