import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setBookingModalStatus,setFiles, setFormDetails} from '../../../../redux/features/shared/sharedSlice';
import { Field, FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import FieldInputText from '../common/InputFeilds/InputTextField';
import * as Yup from "yup";
import { REQUIRED } from '../../../../utils/const';
import { addBookingDetails, updateBookingDetails } from '../../../../redux/features/booking/_bookingAction';
import { useEffect } from 'react';
import { getRooms } from '../../../../redux/features/room/_roomAction';
import Select from 'react-select';
import moment from 'moment';
import { LANG } from '../../../constants/language';

function BookingModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);
  const roomData: any = useSelector((state: any) => state.roomList?.data)||[];
  let currentDate= new Date();
  currentDate.setHours(0,0,0,0);

  const eventFormValidation = Yup.object().shape({
    title: Yup.string().required(REQUIRED),
    startDate: Yup.date().required(REQUIRED),
    endDate: Yup.date().required(REQUIRED).min(currentDate,LANG.DATE_CAN_NOT_BE_PAST),
    room: Yup.string().required(REQUIRED),
    description: Yup.string()
  });

  const formIntialValues = {
    title: sharedActions.formDetails.title || '',
    startDate: sharedActions.formDetails.startDate ? moment(sharedActions.formDetails.startDate).format("YYYY-MM-DD"): '',
    endDate:  sharedActions.formDetails.endDate ? moment(sharedActions.formDetails.endDate).format("YYYY-MM-DD"): '',
    room: sharedActions.formDetails.room || '',
    description: sharedActions.formDetails.description || '',
    approved: sharedActions?.formDetails?.approved || false
  };


  const partnerFormik = useFormik({
    initialValues: formIntialValues,
    validationSchema: eventFormValidation,
    onSubmit: (values: any, { resetForm }) => {
      values.selectedPage =  sharedActions.selectedPage
      if(sharedActions?.formDetails?._id){
        dispatch(updateBookingDetails({...values,id: sharedActions.formDetails?._id }));
      }else{
        dispatch(addBookingDetails(values));
      }
      dispatch(setFormDetails({}))
      setTimeout(() => {
        dispatch(setBookingModalStatus(false))
        resetForm();
      }, 500);
    },
  });

  useEffect(()=>{
    dispatch(getRooms({page:1, limit: 1000}));
    console.log("rooms",roomData)
  },[])

  const closeModal = () => {
    dispatch(setBookingModalStatus(false))
    dispatch(setFormDetails({}))
    dispatch(setFiles([]))
  }

  return (
    <>
      <Modal backdrop="static" size='lg' show={sharedActions.bookingDetailsModal} onHide={closeModal} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>{!sharedActions.formDetails._id ? LANG.ADD: LANG.UPDATE} Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={partnerFormik}>
            <Form onSubmit={partnerFormik.handleSubmit} method='POST'>
              <div className="row">
                <div className="col-sm-12  col-md-4 mb-6">
                  <Form.Group>
                    <Field
                      name="title"
                      validate={eventFormValidation}
                      type="text"
                      label="Title"
                      isRequired={true}
                      component={FieldInputText}
                      placeholder="Enter title"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-12  col-md-4 mb-6">
                  <Form.Group>
                    <Field
                      name="startDate"
                      validate={eventFormValidation}
                      type="date"
                      isRequired={true}
                      label="Start Date"
                      component={FieldInputText}
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-12  col-md-4 mb-6">
                  <Form.Group>
                    <Field
                      name="endDate"
                      validate={eventFormValidation}
                      type="date"
                      isRequired={true}
                      label="End Date"
                      component={FieldInputText}
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12  col-md-12 mb-6">
                  <Form.Group>
                    <Field
                      as={'textarea'}
                      name="description"
                      validate={eventFormValidation}
                      label="Description"
                      component={FieldInputText}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12 col-md-6 mb-6">
                  <Form.Group>
                    <Form.Label>
                      Room <span>*</span>
                    </Form.Label>
                    <Select
                      className='custom-select-box'
                      value={roomData?.find((g)=>g._id === partnerFormik.values?.room)}
                      name="room"
                      placeholder='Select Room'
                      getOptionLabel={(o:any)=>o?.roomName}
                      getOptionValue={(o:any)=>o?._id}
                      onChange={(v) => {
                        partnerFormik.setFieldValue("room", v?._id);
                      }}
                      options={roomData}
                    />
                  </Form.Group>
                  {partnerFormik.errors.room && partnerFormik.touched.room && (
                    <div className="formik-errors text-danger">{`${partnerFormik.errors.room}`}</div>
                  )}
                </div>
              </div>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  className="primaryBtn"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primaryBtn active"
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { BookingModal };