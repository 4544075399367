import { useDispatch, useSelector } from 'react-redux';
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { setBookingModalStatus, setEventModalStatus, setForumModalStatus, setMemberPlanModalStatus, setMemberSubscriptionModalStatus, setNewsModalStatus, setPlanModalStatus, setRoomModalStatus, setSubscriptionModalStatus, setTaskModalStatus, setUserModalStatus, setWebpageModalStatus } from '../../../../../../../redux/features/shared/sharedSlice';

const UsersListToolbar = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch = useDispatch();
  const showIds: string[] = ['User', 'Event', 'Room', 'Task', 'Booking', 'Forum', 'Newsletter', 'Subscription', 'Plan', 'MemberSubscription', 'MemberPlan', 'Webpage']
  const titleObj: any = {
    User: 'User',
    Event: 'Event',
    Room: 'Room',
    Task: 'Task',
    Booking: 'Booking',
    Forum: 'Forum',
    Newsletter: 'Newsletter',
    Subscription: 'Subscription',
    Plan: 'Plan',
    MemberSubscription: 'Member Subscription',
    MemberPlan: 'Member Plan',
    Webpage: 'Web Page'
  }

  const openAddUserModal = () => {

    switch (sharedActions.id) {
      case 'User':
        dispatch(setUserModalStatus(true))
        break;
      case 'Event':
        dispatch(setEventModalStatus(true))
        break;
      case 'Booking':
        dispatch(setBookingModalStatus(true))
        break;
      case 'Room':
        dispatch(setRoomModalStatus(true))
        break;
      case 'Task':
        dispatch(setTaskModalStatus(true));
        break;
      case 'Forum':
        dispatch(setForumModalStatus(true));
        break;
      case 'Newsletter':
        dispatch(setNewsModalStatus(true));
        break;
      case 'Subscription':
        dispatch(setSubscriptionModalStatus(true));
        break;
      case 'Plan':
        dispatch(setPlanModalStatus(true));
        break;
      case 'MemberSubscription':
        dispatch(setMemberSubscriptionModalStatus(true));
        break;
      case 'MemberPlan':
        dispatch(setMemberPlanModalStatus(true));
        break;
      case 'Webpage':
        dispatch(setWebpageModalStatus(true));
        break;
      default:
    }
  }

  return (
    <>
      {showIds.includes(sharedActions.id) && <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add {titleObj[sharedActions.id] || sharedActions.id}
        </button>
      </div>}
    </>
  )
}

export { UsersListToolbar }
