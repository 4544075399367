import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import {  RoomsList } from "./RoomsList";
import { RoomAvailability } from "./RoomAvailability";

const roomBreadcrumbs: Array<PageLink> = [
  {
    title: "Room Management",
    path: "/rooms/list",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const RoomPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={roomBreadcrumbs}>Room List</PageTitle>
              <RoomsList/>
            </>
          }
        />
        <Route
          path=":id/availability"
          element={<RoomAvailability/>}
        />
      </Route>
      <Route index element={<Navigate to="/rooms/list" />} />
    </Routes>
  );
};

export default RoomPage;
