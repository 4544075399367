// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import moment from 'moment'
import { EventStatuCell } from './EventStatusCell'
import { AreaCell } from './AreaCell'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const roomsColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Room Name' className='min-w-50px' />,
    id: 'roomName',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].roomName} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Room/Field' className='min-w-50px' />
    ),
    id: 'assignDate',
    Cell: ({ ...props }) => <TitleCell userObj={capitalizeFirstLetter(props.data[props.row.index]?.roomType)} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Area' className='min-w-50px' />
  //   ),
  //   id: 'area',
  //   Cell: ({ ...props }) => <AreaCell width={props.data[props.row.index]?.area.width} 
  //    height={props.data[props.row.index]?.area.height} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Bookings' className='min-w-50px' />
    ),
    id: 'availability',
    Cell: ({ ...props }) =><Link title='View Bookings' className='ps-5' to={`/rooms/${props.data[props.row.index]?._id}/availability`}>
       <KTIcon iconName='time' className='fs-1 sb_text-blue' /></Link>,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  },
]

export { roomsColumns }
