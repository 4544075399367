import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_PLANS = `${API_URL}/plans/getAllPlans`;
const PLAN_URL = `${API_URL}/plans`;

const GET_ALL_MEMBER_PLANS = `${API_URL}/memberPlans/getAllMemberPlans`;
const MEMBER_PLAN_URL = `${API_URL}/memberPlans`;

export const getPlans = createAsyncThunk(
  "getPlans",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_PLANS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updatePlanDetails = createAsyncThunk(
  "updatePlanDetails",
  async ({values, id, page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${PLAN_URL}/${id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getPlans({page: page || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addPlanDetails = createAsyncThunk(
  "addPlanDetails",
  async ({values,page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${PLAN_URL}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getPlans({ page: page || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deletePlan = createAsyncThunk(
  "deletePlan",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${PLAN_URL}/${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getPlans({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const getMemberPlans = createAsyncThunk(
  "getMemberPlans",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_MEMBER_PLANS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateMemberPlanDetails = createAsyncThunk(
  "updateMemberPlanDetails",
  async ({values, id, page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${MEMBER_PLAN_URL}/${id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getMemberPlans({page: page || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addMemberPlanDetails = createAsyncThunk(
  "addMemberPlanDetails",
  async ({values,page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${MEMBER_PLAN_URL}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getMemberPlans({ page: page || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deleteMemberPlan = createAsyncThunk(
  "deleteMemberPlan",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${MEMBER_PLAN_URL}/${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getMemberPlans({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
