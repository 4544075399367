import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_EXPORT_USER = `${API_URL}/users/getAllUsersForExport`;
const GET_ALL_MEMBERS = `${API_URL}/users/getAllMembers`;
const GET_ALL_TRAINERS = `${API_URL}/users/getAllTrainers`;
const GET_ALL_SPONSORS = `${API_URL}/users/getAllSponsors`;
const GET_ALL_MEMBERS_ONLY = `${API_URL}/users/getAllMembersOnly`;
const GET_ASSIGNED_USER_DETAILS = `${API_URL}/users/`;
const GET_SLOTS = `${API_URL}/users/slots/`;
const UPLOAD_MEDIA_URL = `${API_URL}/media/upload`
export const getAllUserToExport = async () => {
    try {
        const { data } = await axios.get(GET_ALL_EXPORT_USER);
        return data;
    } catch (error: any) {
        return error;
    }
}


export const getAllMembers = async (params:any) => {
    try {
        const { data } = await axios.get(GET_ALL_MEMBERS,{params});
        return data;
    } catch (error: any) {
        return error;
    }
}

export const getAllMembersOnly = async (params:any) => {
    try {
        const { data } = await axios.get(GET_ALL_MEMBERS_ONLY,{params});
        return data;
    } catch (error: any) {
        return error;
    }
}

export const getAllTrainers = async (params:any) => {
    try {
        const { data } = await axios.get(GET_ALL_TRAINERS,{params});
        return data;
    } catch (error: any) {
        return error;
    }
}

export const getAllSponsors = async (params:any) => {
    try {
        const { data } = await axios.get(GET_ALL_SPONSORS,{params});
        return data;
    } catch (error: any) {
        return error;
    }
}

export const getAssignedUser = async (id:any) => {
    try {     
        const { data } = await axios.get(`${GET_ASSIGNED_USER_DETAILS}trainer/${id}/assignedMembers`);
        return data;
    } catch (error: any) {
        return error;
    }
}

export const getSlots = async (payload:any) => {
    try {     
        const { data } = await axios.get(`${GET_SLOTS}${payload.date}`,{params:payload});
        return data;
    } catch (error: any) {
        return error;
    }
}

export const uploadMedia = async (file:any) => {
    try {     
        const formData= new FormData();
        formData.append('file', file);
        const { data } = await axios.post(UPLOAD_MEDIA_URL,formData);
        return data;
    } catch (error: any) {
        return error;
    }
}
