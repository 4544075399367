import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_TEAMS_BY_USER = `${API_URL}/teams/user/`;

export const getAllTeamsByUser = async (userId:string) => {
    try {
        const { data } = await axios.get(GET_ALL_TEAMS_BY_USER+userId);
        return data;
    } catch (error: any) {
        return error;
    }
}
