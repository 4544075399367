
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEventDetail, setEventDetailModalStatus, setId } from "../../../../redux/features/shared/sharedSlice";
import { getRoomBookings } from "../../../../redux/features/booking/_bookingAction"
import './style.scss';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"
import LeftArrowIcon from "../../../icons/LeftArrowIcon";
import { Link, useParams } from "react-router-dom";
import { getAllBookingOfRoom, getAllRoomEvents } from "../../../../services/room.service";
import EventDetail from "../common/event-detail/EventDetail";

interface Event{
  title: string;
  date: string
}
const RoomAvailability = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch: any = useDispatch();
  const [events, setEvents]= useState<Event[]>([]);
  const params:any= useParams();

  useEffect(()=>{
     getBookings();
  },[params?.id]);

  const handleDateClick = (arg) => {
    console.log(arg)
    // alert(arg)
  }
  const handleEventClick = (arg) => {
    dispatch(setEventDetail(arg?.event?.extendedProps));
    dispatch(setEventDetailModalStatus(true))
  }

  const getBookings=async ()=>{
    const result= await getAllRoomEvents(params.id);
    loadBookingEvents(result?.data);
  }

  const loadBookingEvents=(data:any[])=>{
    const bookings: Event[] = data.map((booking: any) => ({
      title: booking.eventType,
      date: booking.date,
      extendedProps: booking
    }));
    setEvents(bookings);
  }

  return (
    <>
      <KTCard>
        <div className="p-3">
          <div className="my-3 d-flex gap-3 px-2">
            <Link to={'/rooms/list'} className="nav-link"> <span>
              <LeftArrowIcon />
            </span></Link>
            <h3> Bookings</h3>
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            locale={'de'}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
          />

        </div>
      </KTCard>
      {sharedActions.eventModal && <EventDetail />}
    </>
  );
};


function renderEventContent(eventInfo) {
  return(
    <>
     <span className="cursor-pointer">
       <strong className="text-capitalize">{eventInfo.event.title}</strong>
     </span>
    </>
  )
}

export { RoomAvailability };
