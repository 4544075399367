import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { getFormData } from "../../../utils";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_NEWS = `${API_URL}/news/getAllNews`;
const ADD_NEWS_DETAILS = `${API_URL}/news`;
const UPDATE_NEWS_DETAILS = `${API_URL}/news/`;

export const getNews = createAsyncThunk(
  "getNews",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_NEWS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateNewsDetails = createAsyncThunk(
  "updateNewsDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const formData= getFormData(values);
      const { data } = await axios.put(`${UPDATE_NEWS_DETAILS}${values.id}`, formData);
      notify(data.responseMessage, 'success');
      dispatch(getNews({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addNewsDetails = createAsyncThunk(
  "addNewsDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const formData= getFormData(values);
      const { data } = await axios.post(`${ADD_NEWS_DETAILS}`, formData);
      notify(data.responseMessage, 'success');
      dispatch(getNews({ page: values.selectedPage || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deleteNews = createAsyncThunk(
  "deleteNews",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${UPDATE_NEWS_DETAILS}${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getNews({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
