import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_ROOMS = `${API_URL}/rooms/getAllRooms`;
const ADD_ROOMS_DETAILS = `${API_URL}/rooms`;
const UPDATE_ROOMS_DETAILS = `${API_URL}/rooms/`;

export const getRooms = createAsyncThunk(
  "getRooms",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_ROOMS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateRoomDetails = createAsyncThunk(
  "updateRoomDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${UPDATE_ROOMS_DETAILS}${values.id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getRooms({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addRoomDetails = createAsyncThunk(
  "addRoomDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${ADD_ROOMS_DETAILS}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getRooms({ page: values.selectedPage || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deleteRoom = createAsyncThunk(
  "deleteRoom",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${UPDATE_ROOMS_DETAILS}${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getRooms({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
