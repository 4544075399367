import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_NEWS_LETTER = `${API_URL}/news-letter/getAllNewsLetters`;
const ADD_NEWS_LETTER_DETAILS = `${API_URL}/news-letter`;
const UPDATE_NEWS_LETTER_DETAILS = `${API_URL}/news-letter/`;

export const getNewsLetters = createAsyncThunk(
  "getNewsLetters",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_NEWS_LETTER}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateNewsLetterDetails = createAsyncThunk(
  "updateNewsLetterDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${UPDATE_NEWS_LETTER_DETAILS}${values.id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getNewsLetters({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addNewsLetterDetails = createAsyncThunk(
  "addNewsLetterDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${ADD_NEWS_LETTER_DETAILS}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getNewsLetters({ page: values.selectedPage || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deleteNewsLetter = createAsyncThunk(
  "deleteNewsLetter",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${UPDATE_NEWS_LETTER_DETAILS}${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getNewsLetters({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
