import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword, requestVerifyAndChangePassword } from '../core/_requests'
import VisibilityBox from '../../apps/common/visibility-box/VisibilityBox'
import { notify } from '../../../../utils/shared'
import { LANG } from '../../../constants/language'

const initialValues = {
  email: '',
  otp: '',
  password: ""
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email(LANG.INVALID_EMAIL_ADDRESS).required(LANG.EMAIL_IS_REQUIRED),
  otp: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(6, 'Maximum 6 symbols')
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [otpSended, setOtpSended] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        if (otpSended) {
          requestVerifyAndChangePassword(values.email, values.otp, values.password)
            .then(({ data: { result } }) => {
              setHasErrors(false)
              setLoading(false);
              setOtpSended(false);
              notify(LANG.PASSWORD_CHANGED_SUCCESSFULLY, 'success');
              navigate('/auth/login');
            })
            .catch(() => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus(LANG.LOGIN_DETAIL_IS_INCORRECT)
            })
        } else {
          requestPassword(values.email)
            .then(({ data: { result } }) => {
              setHasErrors(false)
              setLoading(false);
              setOtpSended(true);
            })
            .catch(() => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus(LANG.LOGIN_DETAIL_IS_INCORRECT)
            })
        }
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>{LANG.FORGOT_PASSWORD}?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Geben Sie Ihre E-Mail-Adresse ein, um das Passwort zurückzusetzen.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Es tut uns leid, es wurden offenbar Fehler festgestellt. Bitte versuchen Sie es erneut.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Übermitteln Sie die OTP, die Sie per Email erhalten haben, um fortzufahren.</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder={LANG.ENTER_EMAIL_ADDRESS}
          autoComplete='off'
          readOnly={otpSended}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <VisibilityBox show={otpSended}>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
          <input
            type='string'
            placeholder={LANG.ENTER_OTP}
            autoComplete='off'
            {...formik.getFieldProps('otp')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.otp && formik.errors.otp },
              {
                'is-valid': formik.touched.otp && !formik.errors.otp,
              }
            )}
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.otp}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-3">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            {LANG.PASSWORD}
          </label>
          <div className="position-relative">
            <input
              placeholder={LANG.PASSWORD}
              type={"password"}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid": formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid": formik.touched.password && !formik.errors.password,
                }
              )}
              name="password"
            />
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      </VisibilityBox>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Anmelden</span>
          {loading && (
            <span className='indicator-progress'>
              {LANG.PLEASE_WAIT}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
           Abbrechen
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
