import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { BookingsList } from "./BookingsList";
import { BookingAvailability } from "./BookingAvailability";

const eventBreadcrumbs: Array<PageLink> = [
  {
    title: "Booking Management",
    path: "/bookings/list",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "Booking Availability",
    path: "/bookings/availability",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const BookingPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={eventBreadcrumbs}>Bookings list</PageTitle>
              <BookingsList/>
            </>
          }
        />
        <Route
          path="availability"
          element={
            <BookingAvailability />
          }
        />
      </Route>
      <Route index element={<Navigate to="/bookings/list" />} />
    </Routes>
  );
};

export default BookingPage;
