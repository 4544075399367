import { UsersListToolbar } from './ListToolbar'
import { CommonListSearchComponent } from './ListSearchComponent'
import ExportIcon from "../../../../../../icons/ExportIcon";
import VisibilityBox from '../../../visibility-box/VisibilityBox';
import exportFromJSON from "export-from-json";

const headers= {
  "firstName":"First Name",
  "lastName":"Last Name",
  "phone":"Phone",
  "email":"Email",
  "gender": "Gender"
};

const UserListHeader = ({ exportObject }: any) => {

  function downloadXml() {
    const data = exportObject?.data;   //dataForXml
    const fileName = exportObject?.fileName;//fieldsAsObjects or fieldsAsStrings, empty list means "use all"
    const exportType = 'xml';
    const fields= headers||[]
    exportFromJSON({data, fileName, fields, exportType})
  }

  return (
    <>
      <div className='card-header border-0 pt-6 justify-content-end'>
        <CommonListSearchComponent />
        <div className='card-toolbar'>
          <UsersListToolbar />
        </div>
        <VisibilityBox show={exportObject}>
          <div className='card-toolbar ms-2'>
              <button type='button' onClick={downloadXml} className='btn btn-primary'>
                <span className='me-2'>
                  <ExportIcon />
                </span>
                Export
              </button>
          </div>
        </VisibilityBox>
      </div>
    </>
  )
}

export { UserListHeader }
