
import clsx from 'clsx'
import { FC } from 'react'

type Props = {
  userObj: any,
  customClass?: string
}
const TitleCell: FC<Props> = ({ userObj, customClass }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className={clsx('text-gray-800 mb-1 text-wrap text-break', customClass)}>
          {userObj ? userObj:"--"}
        </span>
      </div>
    </div>
  )
}

export { TitleCell };
