import React from 'react'

const Loader = () => {
    return (
        <div className="mx-auto">
            <img src="/media/loading.gif" height={300} alt="loading" />
        </div>
    )
}

export default Loader