

import { CommonTable } from "../common/common-list/table/Table";
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../common/common-list/components/pagination/Pagination";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import {getRoomBookings} from "../../../../redux/features/booking/_bookingAction"
import './style.scss';
import { BookingModal } from "./BookingModal";
import { bookingsColumns } from "../common/common-list/table/columns/_bookingColumns";
import { BookingListHeader } from "../common/common-list/components/header/BookingListHeader";

const BookingsList = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch: any = useDispatch();
  const data: any = useSelector((state: any) => state.bookingList?.data)||[];
  const totalRecord  = useSelector((state: any) => state.bookingList?.totalRecord);

  useEffect(() => {
    dispatch(setId('Booking'))
    dispatch(getRoomBookings({ page: 1, limit: 10 }));
  }, [dispatch]);

  const handleClick = (page: number) => {
    dispatch(getRoomBookings({ page: page, limit: 10 }));
  };


  return (
    <>
      <KTCard>
        <BookingListHeader />
        <CommonTable data={data} columns={bookingsColumns} />
        {sharedActions.bookingDetailsModal && <BookingModal />}
        {totalRecord > 10 && <Pagination
          totalRecord={totalRecord}
          handleClick={handleClick}
        />}
      </KTCard>
    </>
  );
};

export { BookingsList };
