import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_WEBPAGES = `${API_URL}/webpages/getAllWebpages`;
const WEBPAGE_URL= `${API_URL}/webpages`;

export const getWebpages = createAsyncThunk(
  "getWebpages",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_WEBPAGES}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateWebpageDetails = createAsyncThunk(
  "updateWebpageDetails",
  async ({values, id, page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${WEBPAGE_URL}/${id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getWebpages({page: page || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addWebpageDetails = createAsyncThunk(
  "addWebpageDetails",
  async ({values,page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${WEBPAGE_URL}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getWebpages({ page: page || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deleteWebpage = createAsyncThunk(
  "deleteWebpage",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${WEBPAGE_URL}/${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getWebpages({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
