import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_SUBSCRIPTIONS = `${API_URL}/subscriptions/getAllSubscriptions`;
const SUBSCRIPTION_URL = `${API_URL}/subscriptions`;

const GET_ALL_MEMBER_SUBSCRIPTIONS = `${API_URL}/memberSubscriptions/getAllMemberSubscriptions`;
const MEMBER_SUBSCRIPTION_URL = `${API_URL}/memberSubscriptions`;

export const getSubscriptions = createAsyncThunk(
  "getSubscriptions",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values;
      const { data } = await axios.get(`${GET_ALL_SUBSCRIPTIONS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateSubscriptionDetails = createAsyncThunk(
  "updateSubscriptionDetails",
  async ({values, id, page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${SUBSCRIPTION_URL}/${id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getSubscriptions({page: page || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addSubscriptionDetails = createAsyncThunk(
  "addSubscriptionDetails",
  async ({values,page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${SUBSCRIPTION_URL}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getSubscriptions({ page: page || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "deleteSubscription",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${SUBSCRIPTION_URL}/${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getSubscriptions({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const getMemberSubscriptions = createAsyncThunk(
  "getMemberSubscriptions",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values;
      const { data } = await axios.get(`${GET_ALL_MEMBER_SUBSCRIPTIONS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateMemberSubscriptionDetails = createAsyncThunk(
  "updateMemberSubscriptionDetails",
  async ({values, id, page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${MEMBER_SUBSCRIPTION_URL}/${id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getMemberSubscriptions({page: page || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addMemberSubscriptionDetails = createAsyncThunk(
  "addMemberSubscriptionDetails",
  async ({values,page}: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${MEMBER_SUBSCRIPTION_URL}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getMemberSubscriptions({ page: page || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const deleteMemberSubscription = createAsyncThunk(
  "deleteMemberSubscription",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${MEMBER_SUBSCRIPTION_URL}/${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getMemberSubscriptions({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
