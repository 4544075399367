import axios from 'axios'
import { AuthModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/profile`
export const LOGIN_URL = `${API_URL}/auth/admin/login`
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/admin/forgot_password`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/admin/reset_password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {  email,
    password,
  }).then((response: any) => {
    return response
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestVerifyAndChangePassword(email: string, otp: string, password: string) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    email,
    otp, password
  })
}

export function getUserByToken(token: any) {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {})
}
