const ThreeDotsIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_321_73610)">
        <path
          d="M13.9993 19.8333C14.6182 19.8333 15.2117 20.0792 15.6493 20.5168C16.0869 20.9543 16.3327 21.5478 16.3327 22.1667C16.3327 22.7855 16.0869 23.379 15.6493 23.8166C15.2117 24.2542 14.6182 24.5 13.9993 24.5C13.3805 24.5 12.787 24.2542 12.3494 23.8166C11.9118 23.379 11.666 22.7855 11.666 22.1667C11.666 21.5478 11.9118 20.9543 12.3494 20.5168C12.787 20.0792 13.3805 19.8333 13.9993 19.8333ZM13.9993 11.6667C14.6182 11.6667 15.2117 11.9125 15.6493 12.3501C16.0869 12.7877 16.3327 13.3812 16.3327 14C16.3327 14.6188 16.0869 15.2123 15.6493 15.6499C15.2117 16.0875 14.6182 16.3333 13.9993 16.3333C13.3805 16.3333 12.787 16.0875 12.3494 15.6499C11.9118 15.2123 11.666 14.6188 11.666 14C11.666 13.3812 11.9118 12.7877 12.3494 12.3501C12.787 11.9125 13.3805 11.6667 13.9993 11.6667ZM13.9993 3.5C14.6182 3.5 15.2117 3.74583 15.6493 4.18342C16.0869 4.621 16.3327 5.21449 16.3327 5.83333C16.3327 6.45217 16.0869 7.04566 15.6493 7.48325C15.2117 7.92083 14.6182 8.16667 13.9993 8.16667C13.3805 8.16667 12.787 7.92083 12.3494 7.48325C11.9118 7.04566 11.666 6.45217 11.666 5.83333C11.666 5.21449 11.9118 4.621 12.3494 4.18342C12.787 3.74583 13.3805 3.5 13.9993 3.5Z"
          fill="#1D2433"
        />
      </g>
      <defs>
        <clipPath id="clip0_321_73610">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ThreeDotsIcon;
