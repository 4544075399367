import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setBookingModalStatus } from "../shared/sharedSlice";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_BOOKINGS = `${API_URL}/bookings/getAllRoomBookings`;
const ADD_BOOKINGS_DETAILS = `${API_URL}/bookings`;
const UPDATE_BOOKINGS_DETAILS = `${API_URL}/bookings/`;
const APPROVE_BOOKING = `${API_URL}/bookings/approve/`;

export const getRoomBookings = createAsyncThunk(
  "getRoomBooking",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_BOOKINGS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateBookingDetails = createAsyncThunk(
  "updateBookingDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${UPDATE_BOOKINGS_DETAILS}${values.id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getRoomBookings({page: values.selectedPage || 1, limit:10}));
      dispatch(setBookingModalStatus(false));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addBookingDetails = createAsyncThunk(
  "addBookingDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${ADD_BOOKINGS_DETAILS}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getRoomBookings({ page: values.selectedPage || 1, limit: 10 }));
      dispatch(setBookingModalStatus(false));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const approveBooking = createAsyncThunk(
  "approveBooking",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.put(`${APPROVE_BOOKING}${values.id}`, values);
      notify(data.responseMessage, 'success'); 
      dispatch(getRoomBookings({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deleteBooking = createAsyncThunk(
  "deleteBooking",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${UPDATE_BOOKINGS_DETAILS}${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getRoomBookings({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
