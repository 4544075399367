
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import { getRoomBookings } from "../../../../redux/features/booking/_bookingAction"
import './style.scss';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"
import LeftArrowIcon from "../../../icons/LeftArrowIcon";
import { Link } from "react-router-dom";

interface Event{
  title: string;
  start: string;
  end: string
}
const BookingAvailability = () => {
  const [events, setEvents]= useState<Event[]>([]);
  const dispatch: any = useDispatch();
  const data: any = useSelector((state: any) => state.bookingList?.data) || [];
  useEffect(() => {
    dispatch(setId('Booking'))
    dispatch(getRoomBookings({ page: 1, limit: 10 }));
  }, [dispatch]);

  useEffect(()=>{
     if(data?.length){
      loadBookingEvents()
     }
  },[data?.length]);

  const handleDateClick = (arg) => {
    // alert(arg.dateStr)
  }

  const loadBookingEvents=()=>{
    const bookings: Event[] = data.map((booking: any) => ({
      title: booking.title,
      start: booking.startDate,
      end: booking.endDate,
    }));
    setEvents(bookings);
  }

  return (
    <>
      <KTCard>
        <div className="p-3">
          <div className="my-3 d-flex gap-3 px-2">
            <Link to={'/bookings/list'} className="nav-link"> <span>
              <LeftArrowIcon />
            </span></Link>
            <h3> Availability</h3>
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            locale={'de'}
            dateClick={handleDateClick}
            eventContent={renderEventContent}
          />

        </div>
      </KTCard>
    </>
  );
};


function renderEventContent(eventInfo) {
  return(
    <>
     <span className="cursor-pointer">
       <strong className="text-capitalize">{eventInfo.event.title}</strong>
     </span>
    </>
  )
}

export { BookingAvailability };
